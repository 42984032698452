import { Component, OnInit, ViewChild } from '@angular/core';
import { APIService, Article } from '../API.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-customer-articles',
  templateUrl: './customer-articles.component.html',
  styleUrls: ['./customer-articles.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomerArticlesComponent implements OnInit {

  constructor(private api: APIService) { }

  //displayedColumnsArticleList: string[] = ['images', 'name', 'measurements', 'weight', 'amount'];
  expandedElement: Article[] | null = null;

  showArticleAttributes: any = localStorage.getItem('showArticleAttributes');


  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  ArticleList!: MatTableDataSource<Article>;
  loadingBar = false;

  // Dynamically create the displayed columns array based on variables
  displayedColumnsArticleList: string[] = this.generateDisplayedColumns();

  // Function to generate the displayed columns array dynamically
  private generateDisplayedColumns(): string[] {
    const defaultColumns: string[] = ['images', 'name', 'amount'];
    const dynamicColumns: string[] = [];

    // Add columns before 'weight'
    defaultColumns.forEach((column) => {
      if (column === 'amount') {
        // Add 'measurements' column before 'amount' if not excluded
        if (this.showArticleAttributes == 'true') {
          dynamicColumns.push('measurements');
        }
      }
      dynamicColumns.push(column);

      // Add 'weight' column after 'name' if not excluded
      if (column === 'name' && this.showArticleAttributes == 'true') {
        dynamicColumns.push('weight');
      }
    });

    return dynamicColumns;
  }

  ngOnInit() {
    this.loadingBar = true;
    this.api.GetMyArticles().then(articles => {
      this.ArticleList = new MatTableDataSource(((articles as any) as Article[]));
      this.ArticleList.paginator = this.paginator;
      //console.log(this.ArticleList);
      this.loadingBar = false;
    });
  }

  applyFilter(filterValue: string) {
    this.ArticleList.filter = filterValue.trim().toLowerCase();
  }

  isRecent(createdAt: string | Date | null): boolean {
    if (!createdAt) {
      return false;
    }

    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const entryDate = typeof createdAt === 'string' ? new Date(createdAt) : createdAt;

    if (isNaN(entryDate.getTime())) {
      console.error('Invalid date format:', createdAt);
      return false;
    }

    return entryDate >= thirtyDaysAgo;
  }

}
