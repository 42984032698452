import {Component, Inject, OnInit, Optional} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {APIService, UserAddress, UserAddressInput} from '../API.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import {resolveAny} from "dns";

@Component({
  selector: 'app-user-settings-address-dialog',
  templateUrl: './user-settings-address-dialog.component.html',
  styleUrls: ['./user-settings-address-dialog.component.scss']
})
export class UserSettingsAddressDialogComponent implements OnInit{

  action: string;
  address: UserAddress;

  // Define the form group to hold all form controls
  addressForm!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<UserSettingsAddressDialogComponent>,
    private api: APIService,
    private http: HttpClient,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    //console.log(data);
    this.address = data.address;
    this.action = data.action;
  }

  ngOnInit() {
    // Initialize form controls within the FormGroup
    this.addressForm = new FormGroup({
      formCompany: new FormControl('', [Validators.required]),
      formName: new FormControl('', [Validators.required]),
      formStreet: new FormControl('', [Validators.required]),
      formZip: new FormControl('', [Validators.required]),
      formCity: new FormControl('', [Validators.required]),
      formCountry: new FormControl('', [Validators.required]),
      formEmail: new FormControl('', [Validators.required, Validators.email]),
      formPhone: new FormControl('', [Validators.required])
    });
  }

  doAction() {
    // Create the address object based on form values
    const address: UserAddressInput = {
      company: this.addressForm.get('formCompany')?.value,
      name: this.addressForm.get('formName')?.value,
      street: this.addressForm.get('formStreet')?.value,
      zip: this.addressForm.get('formZip')?.value,
      city: this.addressForm.get('formCity')?.value,
      country: this.addressForm.get('formCountry')?.value,
      email: this.addressForm.get('formEmail')?.value,
      phone: this.addressForm.get('formPhone')?.value,
    };

    // Close the dialog and pass the address data
    this.dialogRef.close({ event: this.action, data: address });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel', data: this.address });
  }

}
