import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {APIService, Article, UserAddress} from '../API.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-user-settings-address-del-dialog',
  templateUrl: './user-settings-address-del-dialog.component.html',
  styleUrls: ['./user-settings-address-del-dialog.component.scss']
})
export class UserSettingsAddressDelDialogComponent {

  address: UserAddress;

  constructor(
    public dialogRef: MatDialogRef<UserSettingsAddressDelDialogComponent>,
    private api: APIService,
    private http: HttpClient,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    //console.log(data);
    this.address = data.address;
  }

  doAction() {
    this.dialogRef.close({ event: 'löschen', data: this.address });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel', data: this.address });
  }

}
