import { Component, OnInit, ViewChild } from '@angular/core';
import { APIService, Customer, SaveCustomerInput } from '../API.service';
import { v4 } from 'uuid';

import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { CustomerDialogBoxComponent } from '../customer-dialog-box/customer-dialog-box.component';

@Component({
  selector: 'app-admin-customer',
  templateUrl: './admin-customer.component.html',
  styleUrls: ['./admin-customer.component.scss']
})
export class AdminCustomerComponent implements OnInit {

  constructor(private api: APIService, public dialog: MatDialog) { }

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  displayedColumnsCustomerList: string[] = ['name', 'address', 'action'];
  CustomerList: Customer[] = [];
  loadingBar = false;

  openDialog(action: string, obj?: Customer) {
    if (action === 'anlegen') {
      obj = {
        __typename: 'Customer',
        id: '',
        name: '',
        email: '',
        orderNote: '',
        customMailText: '',
        address: {
          __typename: 'Address',
          street: '',
          zip: '',
          city: '',
        },
        features: {
          __typename: 'CustomerFeatures',
          orderAddressMandatory: true,
          orderApprovalProcess: false,
          showArticleAttributes: true,
          pickupAddress: false,
          hideOrderAddress: false,
          specificDeliveryTime: true
        }
      };
    }
    const dialogRef = this.dialog.open(CustomerDialogBoxComponent, {
      data: { ...obj, action }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const data = result.data;
      delete data.action;
      if (result.event === 'anlegen') {
        this.addRowData(data);
      } else if (result.event === 'bearbeiten') {
        this.updateRowData(data);
      } else if (result.event === 'löschen') {
        this.deleteRowData(data);
      } else if (result.event === 'cancel') {
        //console.log('cancelled!');
        return;
      }
    });
  }

  addRowData(data: Customer) {
    this.api.AddCustomer({
      name: data.name!,
      email: data.email,
      orderNote: data.orderNote,
      customMailText: data.customMailText || '',
      costCenter: data.costCenter,
      tags: data.tags,
      pickupNotes: data.pickupNotes,
      address: {
        street: data?.address?.street || '',
        zip: data?.address?.zip || '',
        city: data?.address?.city || '',
        country: data?.address?.country,
      },
      features: {
        showArticleAttributes: data?.features?.showArticleAttributes,
        orderAddressMandatory: data?.features?.orderAddressMandatory,
        orderApprovalProcess: data?.features?.orderApprovalProcess,
        pickupAddress: data?.features?.pickupAddress,
        hideOrderAddress: data?.features?.hideOrderAddress,
        specificDeliveryTime: data?.features?.specificDeliveryTime,
      },
    }).then(() => this.ngOnInit());
  }

  updateRowData(data: Customer) {

    this.api.SaveCustomer(data.id!, {
      name: data.name!,
      email: data.email,
      orderNote: data.orderNote,
      customMailText: data.customMailText || '',
      costCenter: data.costCenter,
      tags: data.tags,
      pickupNotes: data.pickupNotes,
      address: {
        street: data?.address?.street || '',
        zip: data?.address?.zip || '',
        city: data?.address?.city || '',
        country: data?.address?.country,
      },
      features: {
        showArticleAttributes: data?.features?.showArticleAttributes,
        orderAddressMandatory: data?.features?.orderAddressMandatory,
        orderApprovalProcess: data?.features?.orderApprovalProcess,
        pickupAddress: data?.features?.pickupAddress,
        hideOrderAddress: data?.features?.hideOrderAddress,
        specificDeliveryTime: data?.features?.specificDeliveryTime,
      },
    }).then(() => this.ngOnInit());
  }

  deleteRowData(data: Customer) {
    this.api.RemoveCustomer(data.id!).then(() => this.ngOnInit());
  }

  ngOnInit() {
    this.loadingBar = true;
    this.api.GetCustomers().then((customerList) => {
      this.CustomerList = (customerList as Customer[]);
      this.table.renderRows();
      this.loadingBar = false;
    });
  }

}
