import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIService, Article, ArticleAsset } from '../API.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-article-dialog-box',
  templateUrl: './article-dialog-box.component.html',
  styleUrls: ['./article-dialog-box.component.scss']
})
export class ArticleDialogBoxComponent implements OnInit {
  action: string;
  article: Article;
  files: any = [];

  formName!: FormControl;
  formDescription!: FormControl;
  formMeasurements!: FormControl;
  formWeight!: FormControl;
  formAmount!: FormControl;
  formTags!: FormArray; // Use definite assignment assertion for formTags

  taglist: string[];
  // Tags selected by the user
  tags: string[] = []; // Initially empty or populated based on the article's existing tags


  showArticleAttributes: any = localStorage.getItem('showArticleAttributes');

  constructor(
    public dialogRef: MatDialogRef<ArticleDialogBoxComponent>,
    private api: APIService,
    private http: HttpClient,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    //console.log(data);
    this.article = data.article;
    this.action = data.action;
    this.taglist = data.taglist;
  }

  ngOnInit() {
    // Initialize form controls
    this.formName = new FormControl('', [Validators.required]);
    this.formDescription = new FormControl('', [Validators.required]);

    if (this.showArticleAttributes === 'true') {
      this.formMeasurements = new FormControl('', [Validators.required]);
      this.formWeight = new FormControl('', [Validators.required]);
      this.formAmount = new FormControl('', [Validators.required]);

    } else {
      this.formMeasurements = new FormControl('');
      this.formWeight = new FormControl('');
      this.formAmount = new FormControl('', [Validators.required]);
    }
    //console.log(this.taglist);
    // Initialize checkbox state based on the tags array
    this.checkInitialTags();
  }

  // Method to check if tags already exist in the tags array
  checkInitialTags(): void {
    // Initialize the tags array properly, ensuring it is always a string array
    this.tags = Array.isArray(this.article.tags)
      ? (this.article.tags.filter(tag => tag !== null) as string[])
      : [];  // Fallback to an empty array if article.tags is null or undefined
  }

  // Method to handle checkbox change
  onTagChange(tag: string, isChecked: boolean): void {
    if (isChecked) {
      // Add tag to the array if not already present
      if (!this.tags.includes(tag)) {
        this.tags.push(tag);
      }
    } else {
      // Remove tag from the array
      const index = this.tags.indexOf(tag);
      if (index >= 0) {
        this.tags.splice(index, 1);
      }
    }
    //console.log(this.tags + '/' + this.article.tags); // Optional: See the updated tags array in the console
  }

  doAction() {
    this.article.tags = this.tags;
    this.dialogRef.close({ event: this.action, data: this.article });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel', data: this.article });
  }

  // File upload functionality
  async uploadFile(event: Event) {
    const fileList = (event.target as HTMLInputElement).files;

    if (fileList) {
      //console.log('Uploading files...');
      for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        //console.log(file);

        // Replace spaces with underscores in the filename
        const originalFilename = file.name;
        const modifiedFilename = originalFilename?.replace(/\s+/g, '_');

        // Add article asset to the database and get upload URL
        const uploadMeta = await this.api.AddArticleAsset(
          this.article.customerId!,
          this.article.id!,
          modifiedFilename,
          file.type
        );
        //console.log(`Created asset ${modifiedFilename} with id ${uploadMeta.id}`);

        // Upload asset to S3 using the pre-signed URL
        await this.http.put(uploadMeta.uploadUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        }).toPromise();
        //console.log(`Uploaded asset with id ${uploadMeta.id}`);

        // Add data eagerly for UI before next reload
        if (!this.article.assets) {
          this.article.assets = [];
        }
        this.article.assets.push({
          __typename: 'ArticleAsset',
          id: uploadMeta.id,
          mediaType: file.type,
          name: file.name,
          url: uploadMeta.assetUrl,
        });
      }
    }
  }

  // Delete attachment functionality
  async deleteAttachment(assetId: string | undefined, index: number) {
    if (!assetId) {
      return;
    }
    //console.log(assetId);
    await this.api.RemoveArticleAsset(this.article.customerId!, this.article.id!, assetId);
    this.article.assets?.splice(index, 1);
  }
}
