import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from './app.service';
import { Subscription } from 'rxjs';

import Auth from '@aws-amplify/auth';
import { Router, NavigationError, NavigationCancel, NavigationEnd } from '@angular/router';
import { APIService, Customer } from "./API.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit, OnDestroy {

  admin: boolean = false;
  customer: boolean = false;
  approver: boolean = false;
  subscription: Subscription;
  customerLogoUrl?: string;
  now = new Date().getTime();


  showArticleAttributes: string = '';
  orderAddressMandatory: string = '';
  orderApprovalProcess: string = '';
  pickupAddress: string = '';
  hideOrderAddress: string = '';
  specificDeliveryTime: string = '';

  constructor(private api: APIService, private appService: AppService, private router: Router) {
    // subscribe to login component messages
    this.subscription = this.appService.getMessage().subscribe(message => {
      if (message.text === 'admin') {
        this.updateUserInfo();
        //console.log(message.text);
      } else if (message.text === 'customer') {
        this.updateUserInfo();
        //console.log(message.text);
      } else {
        //console.log('cleared');
        this.admin = false;
        this.customer = false;
      }
    });
  }

  logout() {
    Auth.signOut();
    this.admin = false;
    this.customer = false;
    this.approver = false;
  }

  async updateUserInfo() {
    const user = await Auth.currentAuthenticatedUser();
    //console.log("Benutzer: "+ user.username);
    if (user && user.signInUserSession.idToken.payload['cognito:groups']
      && user.signInUserSession.idToken.payload['cognito:groups'].indexOf('admin') >= 0) {
      this.admin = true;
      //console.log('Admin');
    }
    if (user && user.signInUserSession.idToken.payload['cognito:groups']
      && user.signInUserSession.idToken.payload['cognito:groups'].indexOf('approver') >= 0) {
      this.approver = true;
      //console.log('Approver');
    }
    if (user && user.signInUserSession.idToken.payload['custom:customer']) {
      this.customer = true;
      //console.log('Customer');
      this.api.GetMyCustomer().then((customer) => {

        if (customer.features?.showArticleAttributes != null) {
          this.showArticleAttributes = customer.features?.showArticleAttributes.toString();
          localStorage.setItem('showArticleAttributes', this.showArticleAttributes);
        }
        if (customer.features?.orderAddressMandatory != null) {
          this.orderAddressMandatory = customer.features?.orderAddressMandatory.toString();
          localStorage.setItem('orderAddressMandatory', this.orderAddressMandatory);
        }
        if (customer.features?.orderApprovalProcess != null) {
          this.orderApprovalProcess = customer.features?.orderApprovalProcess.toString();
          localStorage.setItem('orderApprovalProcess', this.orderApprovalProcess);
        }
        if (customer.features?.pickupAddress != null) {
          this.pickupAddress = customer.features?.pickupAddress.toString();
          localStorage.setItem('pickupAddress', this.pickupAddress);
        }
        if (customer.features?.hideOrderAddress != null) {
          this.hideOrderAddress = customer.features?.hideOrderAddress.toString();
          localStorage.setItem('hideOrderAddress', this.hideOrderAddress);
        }
        if (customer.features?.specificDeliveryTime != null) {
          this.specificDeliveryTime = customer.features?.specificDeliveryTime.toString();
          localStorage.setItem('specificDeliveryTime', this.specificDeliveryTime);
        }

        if (customer.logoUrl) {
          this.customerLogoUrl = customer.logoUrl + '?v=' + this.now;
        }
      });
    }
  }

  async ngOnInit() {
    try {
      await this.updateUserInfo();
    } catch (e) {
      if (e === 'not authenticated') {
        this.router.navigate(['/login']);
      }
      console.log(e);
    }

  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

}


