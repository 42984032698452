<div id="main" role="main">
  <h1>{{ 'global.artikel' | transloco }}</h1>
  <i>{{ 'customerarticle.titel' | transloco }}</i>

  <h3>{{ 'customerarticle.sub' | transloco }}</h3>

  <div class="table-container">

    <mat-form-field>
      <input matInput #searchInput (keyup)="applyFilter(searchInput.value)" placeholder="{{ 'customerarticle.search' | transloco }}">
    </mat-form-field>

    <div class="mat-elevation-z8">
      <mat-progress-bar *ngIf="loadingBar" mode="query"></mat-progress-bar>
      <table mat-table multiTemplateDataRows [dataSource]="ArticleList" class="article-table">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Image Column -->
        <ng-container matColumnDef="images">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let articles" class="imageContainer" [ngClass]="{'newArticle':  isRecent(articles?.createdAt)}">
              <div class="imageBox">
                <ng-container *ngFor="let file of articles?.assets; let i= index">
                  <img class="uploadedImage" [src]="file.url"/>
                </ng-container>
              </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container *ngIf="showArticleAttributes == 'true'">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{ 'customerarticle.description' | transloco }} </th>
            <td mat-cell *matCellDef="let articles"> {{articles.name}} </td>
          </ng-container>

          <!-- Maße Column -->
          <ng-container matColumnDef="measurements">
            <th mat-header-cell *matHeaderCellDef> {{ 'customerarticle.measurements' | transloco }} </th>
            <td mat-cell *matCellDef="let articles"> {{articles.measurements}} </td>
          </ng-container>

          <!-- Gewicht Column -->
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> {{ 'customerarticle.weight' | transloco }} </th>
            <td mat-cell *matCellDef="let articles"> {{articles.weight}} </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showArticleAttributes == 'false'">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{ 'customerarticle.descriptionMeasurements' | transloco }} </th>
            <td mat-cell *matCellDef="let articles"> {{articles.name}} </td>
          </ng-container>
        </ng-container>

        <!-- Anzahl Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> {{ 'customerarticle.amount' | transloco }} </th>
          <td mat-cell *matCellDef="let articles"> {{articles.amount}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let article" [attr.colspan]="displayedColumnsArticleList.length">
            <div class="article-detail"
                 [@detailExpand]="article == expandedElement ? 'expanded' : 'collapsed'">

              <div class="article-images">
                <ng-container *ngFor="let file of article.assets;">
                  <img class="article-image" [src]="file.url" alt="Artikelbild"/>
                </ng-container>
              </div>

              <h4>{{ 'customerarticle.descriptiontext' | transloco }}</h4>
              <p>{{article.description}}</p>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsArticleList"></tr>
        <tr mat-row *matRowDef="let article; columns: displayedColumnsArticleList;"
            class="article-row"
            [class.expanded-row]="expandedElement === article"
            (click)="expandedElement = expandedElement === article ? null : article">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

      </table>

      <mat-paginator [pageSizeOptions]="[5, 25, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
