import { Component, OnInit, ViewChild } from '@angular/core';
import {APIService, Article, Customer, Order, UserAddress, UserAddressInput} from '../API.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NewOrderDialogBoxComponent } from '../new-order-dialog-box/new-order-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Auth from "@aws-amplify/auth";
import {ArticleDialogBoxComponent} from "../article-dialog-box/article-dialog-box.component";
import {UserSettingsAddressDialogComponent} from "../user-settings-address-dialog/user-settings-address-dialog.component";
import {UserSettingsAddressDelDialogComponent} from "../user-settings-address-del-dialog/user-settings-address-del-dialog.component";
import {ArticleRemoveDialogBoxComponent} from "../article-remove-dialog-box/article-remove-dialog-box.component";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
  constructor(private api: APIService, public dialog: MatDialog, private router: Router) { }

  loadingBar = false;
  userAddresses: any;
  newAddress: any;

  async ngOnInit() {
    this.loadingBar = true;

    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        //console.log('Benutzer: ' + user.username);
        this.userAddresses = await this.api.GetUserAddresses();
        //console.log('Adressen: ' + JSON.stringify(this.userAddresses[0]));
        this.loadingBar = false;
      }
    } catch (e) {
      if (e === 'not authenticated') {
        this.router.navigate(['/login']);
      }
      //console.log(e);
      this.loadingBar = false;
    }

  }


  openAddressDialog(action: string, address?: UserAddress) {
    if (action === 'anlegen') {
      address = {
        __typename: 'UserAddress',
        id: '',
        company: '',
        name: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        email: '',
        phone: ''
      };
    }
    const dialogRef = this.dialog.open(UserSettingsAddressDialogComponent, {
      data: { address, action },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const data = result.data;
      delete data.action;

      if (result.event === 'anlegen') {
        this.addAddress(data);
      } else if (result.event === 'bearbeiten') {
        this.editAddress(data.id,data);
      } else if (result.event === 'Cancel') {
        //this.canceledArticleDialog();
      }
    });
  }

  async addAddress(addressData:UserAddressInput) {
    this.loadingBar = true;

    try {
      const result = await this.api.AddUserAddress(addressData);
      if (result) {
        //console.log("Address added successfully:", result);
        this.ngOnInit();
      }
    } catch (error) {
      this.loadingBar = false;
      console.error("Error adding address:", error);
    }
  }


  async removeAddress(id:string) {
    this.loadingBar = true;
    try {
      const result = await this.api.RemoveUserAddress(id);
      if (result) {
        //console.log("Address deleted successfully:", result);
        this.ngOnInit();
      }
    } catch (error) {
      this.loadingBar = false;
      console.error("Error adding address:", error);
    }
  }


  async editAddress(id:string,addressData:UserAddressInput) {
    this.loadingBar = true;

    try {
      const result = await this.api.ChangeUserAddress(id,addressData);
      if (result) {
        //console.log("Address changed successfully:", result);
        this.ngOnInit();
      }
    } catch (error) {
      this.loadingBar = false;
      console.error("Error adding address:", error);
    }
  }

  openRemoveAddressDialog(address: UserAddress) {
    const dialogRef = this.dialog.open(UserSettingsAddressDelDialogComponent, {
      data: { address },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const data = result.data;
      delete data.action;

      if (result.event === 'löschen') {
        this.removeAddress(data.id);
      } else if (result.event === 'Cancel') {
        //this.canceledArticleDialog();
      }
    });
  }


}
