<div id="main" role="main">
  <div class="action-row" style="float:right;">
    <button mat-fab color="primary" attr.aria-label="{{ 'customerneworder.edit' | transloco }}" (click)="openDialog('bearbeiten')"><mat-icon>edit</mat-icon></button>
  </div>
  <h1>{{ 'customerneworder.titel' | transloco }}</h1>
  <i>{{ 'customerneworder.sub' | transloco }}</i>



  <h2>{{OrderDetails?.eventName}}</h2>
  <i>{{ 'customerneworder.number' | transloco }} {{orderID}}</i>

  <p>{{ 'customerneworder.from' | transloco }} <b>{{OrderDetails?.deliveryDate | date:'dd.MM.yyyy'}}</b> {{ 'customerneworder.to' | transloco }} <b>{{OrderDetails?.pickupDate | date:'dd.MM.yyyy'}}</b></p>
  <p *ngIf="OrderDetails?.orderer">{{ 'global.orderer' | transloco }} <b>{{OrderDetails?.orderer}}</b></p>

  <p *ngIf="OrderDetails?.costCenter">{{ 'customerneworder.costCenter' | transloco }} <b>{{OrderDetails?.costCenter}}</b></p>

  <hr>
  <p *ngIf="OrderDetails?.deliveryWindow">{{ 'customerneworder.delivery' | transloco }} <b>{{OrderDetails?.deliveryWindow}}</b></p>
  <p *ngIf="OrderDetails?.pickupWindow">{{ 'customerneworder.pickup' | transloco }} <b>{{OrderDetails?.pickupWindow}}</b></p>
  <p *ngIf="OrderDetails?.eventStartDate">{{ 'customerneworder.eventStartDate' | transloco }}: {{OrderDetails?.eventStartDate | date:'dd.MM.yyyy'}}</p>
  <p *ngIf="OrderDetails?.eventEndDate">{{ 'customerneworder.eventEndDate' | transloco }}: {{OrderDetails?.eventEndDate | date:'dd.MM.yyyy'}}</p>

  <h3 *ngIf="OrderDetails?.additionalEmailAddresses">{{ 'customerneworder.additionalMail' | transloco }}</h3>
  <ul *ngIf="OrderDetails?.additionalEmailAddresses">
    <li *ngFor="let mail of OrderDetails?.additionalEmailAddresses; let i= index">
      {{mail}}
    </li>
  </ul>
  <hr>

  <h3>{{ 'customerneworder.address' | transloco }}</h3>
  <p>{{OrderDetails?.deliveryAddress?.street}}</p>
  <p>{{OrderDetails?.deliveryAddress?.zip}} {{OrderDetails?.deliveryAddress?.city}}</p>
  <p>{{OrderDetails?.deliveryAddress?.country}}</p>

  <h3>{{ 'customerneworder.contact' | transloco }}</h3>
  <p>{{OrderDetails?.deliveryAddress?.company}}</p>
  <p>{{OrderDetails?.deliveryAddress?.contactName}}</p>
  <ng-container *ngIf="OrderAddressMandatory == 'true' && OrderDetails?.orderAddress">
    <h3>{{ 'customerneworder.orderaddress' | transloco }}</h3>
    <p>{{OrderDetails?.orderAddress?.company}}</p>
    <p>{{OrderDetails?.orderAddress?.street}}</p>
    <p>{{OrderDetails?.orderAddress?.zip}} {{OrderDetails?.orderAddress?.city}}</p>
    <p>{{OrderDetails?.orderAddress?.country}}</p>
  </ng-container>
  <ng-container *ngIf="OrderDetails?.pickupAddress?.company">
    <h3>{{ 'customerneworder.pickupaddress' | transloco }}</h3>
    <p>{{OrderDetails?.pickupAddress?.company}}</p>
    <p>{{OrderDetails?.pickupAddress?.street}}</p>
    <p>{{OrderDetails?.pickupAddress?.zip}} {{OrderDetails?.pickupAddress?.city}}</p>
    <p>{{OrderDetails?.pickupAddress?.country}}</p>
  </ng-container>


 


  <div *ngIf="!selectingArticles && ((OrderDetails?.articles ?? []).length > 0)">
    <button mat-fab color="primary" attr.aria-label="{{ 'customerneworder.edit' | transloco }}" (click)="selectingArticles = true" style="float:right;"><mat-icon>edit</mat-icon></button>
    <h3>{{ 'customerneworder.selectedarticles' | transloco }}</h3>

    <ul>
      <li *ngFor="let article of OrderDetails?.articles; let i= index">
        <b>{{getArticleNameById(article?.id)}}</b> | {{ 'customerneworder.amount' | transloco }}: {{article?.amount}}
      </li>
    </ul>
  </div>


  <div *ngIf="selectingArticles || !((OrderDetails?.articles ?? []).length > 0)">
    <h3>{{ 'customerneworder.availablearticles' | transloco }}</h3>
      <div class="table-container">

      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'global.search' | transloco }}">
      </mat-form-field>

      <div class="mat-elevation-z8">
            <mat-progress-bar *ngIf="loadingBar" mode="query"></mat-progress-bar>
        <table mat-table [dataSource]="AvailableArticles" class="article-table">

          <ng-container matColumnDef="images">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let articles" class="imageContainer" [ngClass]="{'newArticle':  isRecent(articles?.createdAt)}">
              <div class="imageBox">
                <ng-container *ngFor="let file of articles?.assets; let i= index">
                  <img class="uploadedImage" [src]="file.url"/>
                </ng-container>
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="showArticleAttributes == 'true'">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> {{ 'customerneworder.name' | transloco }} </th>
              <td mat-cell *matCellDef="let row"> {{row.name}}
                <div class="article-tags-list">
                  <span *ngFor="let tag of row.tags">{{tag}}</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="measurements">
              <th mat-header-cell *matHeaderCellDef> {{ 'customerneworder.measurements' | transloco }} </th>
              <td mat-cell *matCellDef="let row"> {{row.measurements}} </td>
            </ng-container>

            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef> {{ 'customerneworder.weight' | transloco }} </th>
              <td mat-cell  *matCellDef="let row"> {{row.weight}} </td>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="showArticleAttributes == 'false'">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> {{ 'customerneworder.nameMeasurements' | transloco }} </th>
              <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> {{ 'customerneworder.available' | transloco }} </th>
            <td mat-cell *matCellDef="let row"> {{row.availableAmount}} {{ 'customerneworder.of' | transloco }} {{row.amount}} </td>
          </ng-container>

          <ng-container matColumnDef="orderAmount">
            <th mat-header-cell *matHeaderCellDef> {{ 'customerneworder.amount' | transloco }} </th>
            <td mat-cell *matCellDef="let row">
              <div class="input-group">
                <div class="value-button decrease" (click)="row.orderAmount > 0 ? row.orderAmount = row.orderAmount - 1 : row.orderAmount = 0" attr.value="{{ 'customerneworder.less' | transloco }}">-</div>
                <input type="number" min="0" max="row.amount" matInput [value]="0" [(ngModel)]="row.orderAmount">
                <div class="value-button increase" (click)="row.orderAmount < row.amount ? row.orderAmount = row.orderAmount + 1 : row.orderAmount = row.amount" attr.value="{{ 'customerneworder.more' | transloco }}">+</div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsArticleList"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsArticleList;"></tr>
        </table>
      </div>
    </div>
    <span *ngIf="!((OrderDetails?.articles ?? []).length > 0)" style="color:#e40c7d;">{{ 'customerneworder.noArticlesHint' | transloco }}</span>
    <button *ngIf="selectingArticles || !((OrderDetails?.articles ?? []).length > 0)"
      mat-button color="primary" mat-raised-button style="float:right;"
            attr.aria-label="{{ 'customerneworder.proceed' | transloco }}"
            (click)="updateOrder(AvailableArticles, OrderDetails)">
      {{ 'customerneworder.proceed' | transloco }}
    </button>

  </div>
  <button *ngIf="!selectingArticles && ((OrderDetails?.articles ?? []).length > 0)"
          mat-button color="primary" mat-raised-button style="float:right;"
          attr.aria-label="{{ 'customerneworder.save' | transloco }}"
          (click)="saveOrder(AvailableArticles, OrderDetails)">
    {{ 'customerneworder.save' | transloco }}
  </button>

</div>
