<mat-toolbar color="primary" class="no-print">
  <mat-toolbar-row class="toolbarHeader" role="header">
    <img src="assets/img/imaging-you.svg" width="200px">
    <img *ngIf="customerLogoUrl" class="toolbarHeader__customerLogo" [src]="customerLogoUrl"> <!-- todo: mgress use file from customer -->
    <div>{{ 'global.title' | transloco }}</div>
    <!--<app-language-selector class="app-language-selector"></app-language-selector>-->

  </mat-toolbar-row>

  <mat-toolbar-row class="toolbarNavigation" style="background:  mat-color($md-imgyprimary, 800);">
    <div class="mobileMenu" *ngIf="customer || admin">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menubutton">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu" role="navigation">
        <button mat-menu-item [routerLink]="'admin-orders-list'">
          <mat-icon>assignment</mat-icon>
          <span>{{ 'global.bestellungen' | transloco }}</span>
        </button>
        <button mat-menu-item [routerLink]="'admin-customer'" *ngIf="admin">
          <mat-icon>apartment</mat-icon>
          <span>{{ 'global.verwaltung' | transloco }}</span>
        </button>
        <button mat-menu-item [routerLink]="'admin-orders-report'" *ngIf="admin">
          <mat-icon>money</mat-icon>
          <span>{{ 'global.abrechnung' | transloco }}</span>
        </button>
        <button mat-menu-item [routerLink]="'customer-articles'" *ngIf="customer">
          <mat-icon>shopping_cart</mat-icon>
          <span>{{ 'global.artikel' | transloco }}</span>
        </button>
        <button mat-menu-item [routerLink]="'customer-orders'" *ngIf="customer">
          <mat-icon>assignment</mat-icon>
          <span>{{ 'global.meinebestellungen' | transloco }}</span>
        </button>
        <button mat-menu-item [routerLink]="'admin-user'" *ngIf="admin">
          <mat-icon>people</mat-icon>
          <span>{{ 'global.benutzerliste' | transloco }}</span>
        </button>
        <button mat-menu-item (click)="logout()" [routerLink]="'login'">
          <mat-icon>power_settings_new</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
    <span class="menu-spacer"></span>

    <div class="desktopMenu">
      <div>
        <a mat-button [routerLink]="'admin-orders-list'" *ngIf="admin"> <mat-icon>assignment</mat-icon> {{ 'global.bestellungen' | transloco }} </a>
        <a mat-button [routerLink]="'admin-customer'" *ngIf="admin"> <mat-icon>apartment</mat-icon> {{ 'global.verwaltung' | transloco }} </a>
        <a mat-button [routerLink]="'admin-orders-report'" *ngIf="admin"> <mat-icon>money</mat-icon> {{ 'global.abrechnung' | transloco }} </a>
        <a mat-button [routerLink]="'admin-user'" *ngIf="admin"> <mat-icon>people</mat-icon> {{ 'global.benutzerliste' | transloco }} </a>
        <a mat-button [routerLink]="'customer-articles'" *ngIf="customer"> <mat-icon>shopping_cart</mat-icon> {{ 'global.artikel' | transloco }} </a>
        <a mat-button [routerLink]="'customer-orders'" *ngIf="customer"> <mat-icon>assignment</mat-icon> {{ 'global.meinebestellungen' | transloco }} </a>
        <a mat-button [routerLink]="'user-settings'" *ngIf="customer"> <mat-icon>settings</mat-icon> {{ 'global.einstellungen' | transloco }} </a>

      </div>
      <div class="desktopMenu--right">
        <!--<a mat-button [routerLink]="'login'"><mat-icon>arrow_forward</mat-icon> Login </a> entfällt später da direkt bei Aufruf geprüft wird und automatisch der Login angezeigt wird -->
        <a mat-button [routerLink]="'login'" (click)="logout()" *ngIf="customer || admin"><mat-icon>power_settings_new</mat-icon> Logout </a>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<router-outlet></router-outlet>
