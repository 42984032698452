<div id="main" role="main">
  <h1>{{ 'settings.title' | transloco }}</h1>
  <!-- <i>{{ 'customerorders.sub' | transloco }}</i> -->
  <div class="userSettings_addresses_header">
    <h3>{{ 'settings.userAdresses' | transloco }}</h3>
    <button (click)="openAddressDialog('anlegen', undefined)" color="accent" mat-raised-button  attr.aria-label="{{ 'settings.newAddress' | transloco }}"><mat-icon>add</mat-icon> {{ 'settings.newAddress' | transloco }}</button>
  </div>
  <mat-progress-bar *ngIf="loadingBar" mode="query"></mat-progress-bar>

  <div class="userSettings_addresses">
      <div *ngFor="let address of userAddresses; let i= index" class="userSettings_addressBox">
        <h4>{{address.company}}</h4>
        <p *ngIf="address.name">{{address.name}}</p>
        <p>
          <!--<span *ngIf="address.id">{{address.id}}<br></span>-->
          <span *ngIf="address.street">{{address.street}}<br></span>
          <span *ngIf="address.zip">{{address.zip}} {{address.city}}<br></span>
          <span *ngIf="address.country">{{address.country}}</span>
        </p>
        <p>
          <span *ngIf="address.email">{{address.email}}<br></span>
          <span *ngIf="address.phone">{{address.phone}}<br></span>
        </p>
        <!--<button (click)="removeAddress(address.id)">delete</button>-->
        <div class="userSettings_addresses_functions">
          <a (click)="openRemoveAddressDialog(address)"><mat-icon color="warn">delete</mat-icon></a>
          <a (click)="openAddressDialog('bearbeiten',address)" title="{{ 'settings.editAddress' | transloco }}"><mat-icon>edit</mat-icon></a>
        </div>

      </div>
    </div>



  <!--<button (click)="addAddress()">Add Address</button>-->

  <!--
  <button (click)="openDialog('anlegen')" mat-btn color="accent" mat-raised-button  attr.aria-label="{{ 'customerorders.newcustomer' | transloco }}"><mat-icon>add</mat-icon> {{ 'customerorders.new' | transloco }}</button>
  -->

</div>
