<h1 mat-dialog-title *ngIf="action == 'bearbeiten'">{{ 'articledialog.editArticle' | transloco }}</h1>
<h1 mat-dialog-title *ngIf="action == 'anlegen'">{{ 'articledialog.newArticle' | transloco }}</h1>
<h1 mat-dialog-title *ngIf="action == 'upload'">{{ 'articledialog.addImages' | transloco }}</h1>
<div mat-dialog-content class="form">

  <mat-form-field class="dataFieldMax" *ngIf="action != 'upload' && showArticleAttributes == 'true'">
    <input placeholder="{{ 'articledialog.name' | transloco }}" matInput [(ngModel)]="article.name" [formControl]="formName" required>
    <mat-error *ngIf="formName.invalid">{{ 'articledialog.hintname' | transloco }}</mat-error>
  </mat-form-field>

  <mat-form-field class="dataFieldMax" *ngIf="action != 'upload' && showArticleAttributes == 'false'">
    <input placeholder="{{ 'articledialog.nameMeasurements' | transloco }}" matInput [(ngModel)]="article.name" [formControl]="formName" required>
    <mat-error *ngIf="formName.invalid">{{ 'articledialog.hintname' | transloco }}</mat-error>
  </mat-form-field>

  <ng-container *ngIf="action == 'upload' || article.assets?.length">
    <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
      <mat-icon color="primary">add_photo_alternate</mat-icon>
      <input hidden type="file" #fileInput (change)="uploadFile($event)" multiple>
    </div>
    <div class="files-list">
      <ng-container *ngFor="let file of article.assets;let i= index">
        <div class="uploadedImage" [ngStyle]="{'background-image': 'url(' + file?.url + ')'}">
          <button mat-mini-fab color="warn" class="delete-file" (click)="deleteAttachment(file?.id, i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <form *ngIf="action != 'upload' && showArticleAttributes == 'true'">
    <mat-form-field class="dataFieldMax">
      <textarea rows="5" placeholder="{{ 'articledialog.description' | transloco }}" matInput [(ngModel)]="article.description" [formControl]="formDescription" required></textarea>
      <mat-error *ngIf="formDescription.invalid">{{ 'articledialog.hintdescription' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataField33">
      <input placeholder="{{ 'articledialog.measurements' | transloco }}" matInput [(ngModel)]="article.measurements" [formControl]="formMeasurements" required>
      <mat-error *ngIf="formMeasurements.invalid">{{ 'articledialog.hintmeasurements' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataField33">
      <input placeholder="{{ 'articledialog.weight' | transloco }}" matInput [(ngModel)]="article.weight" [formControl]="formWeight" required>
      <mat-error *ngIf="formWeight.invalid">{{ 'articledialog.hintweight' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataField33">
      <input placeholder="{{ 'articledialog.amount' | transloco }}" matInput [(ngModel)]="article.amount" [formControl]="formAmount" required>
      <mat-error *ngIf="formAmount.invalid">{{ 'articledialog.hintamount' | transloco }}</mat-error>
    </mat-form-field>
    <ng-container *ngIf="taglist.length > 0">
      <h4>Tags</h4>
        <div *ngFor="let tag of taglist" class="tagsPill">
          <mat-checkbox
            [checked]="tags.includes(tag)"
            (change)="onTagChange(tag, $event.checked)">
            {{ tag }}
          </mat-checkbox>
        </div>
    </ng-container>
  </form>

  <form *ngIf="action != 'upload' && showArticleAttributes == 'false'">
    <mat-form-field class="dataFieldMax">
      <textarea rows="5" placeholder="{{ 'articledialog.description' | transloco }}" matInput [(ngModel)]="article.description" [formControl]="formDescription" required></textarea>
      <mat-error *ngIf="formDescription.invalid">{{ 'articledialog.hintdescription' | transloco }}</mat-error>
    </mat-form-field>

    <mat-form-field class="dataField33 pull-right">
      <label>{{ 'articledialog.amount' | transloco }}</label>
      <input placeholder="{{ 'articledialog.amount' | transloco }}" matInput [(ngModel)]="article.amount" [formControl]="formAmount" required>
      <mat-error *ngIf="formAmount.invalid">{{ 'articledialog.hintamount' | transloco }}</mat-error>
    </mat-form-field>

  </form>
</div>

<div mat-dialog-actions class="actions">
  <span class="actionHint" *ngIf="(formName.invalid || formDescription.invalid || formMeasurements.invalid || formWeight.invalid || formAmount.invalid) && action != 'upload'">{{ 'articledialog.hintmandatory' | transloco }}</span>
  <button mat-button (click)="doAction()" [disabled]="(formName.invalid || formDescription.invalid || formMeasurements.invalid || formWeight.invalid || formAmount.invalid) && action != 'upload'" mat-flat-button color="accent">{{ 'articledialog.buttonOK' | transloco }}</button>
  <button mat-button (click)="closeDialog()">{{ 'articledialog.buttonCancel' | transloco }}</button>
</div>
