<h1 mat-dialog-title *ngIf="action == 'bearbeiten'">{{ 'settingsaddressdialog.editAddress' | transloco }}</h1>
<h1 mat-dialog-title *ngIf="action == 'anlegen'">{{ 'settingsaddressdialog.newAddress' | transloco }}</h1>
<div mat-dialog-content class="form">

  <form [formGroup]="addressForm">
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'settingsaddressdialog.name' | transloco }}" matInput [(ngModel)]="address.name" formControlName="formName" required>
      <mat-error *ngIf="addressForm.get('formName')?.invalid">{{ 'settingsaddressdialog.hintname' | transloco }}</mat-error>
    </mat-form-field>
  <mat-form-field class="dataFieldMax">
    <input placeholder="{{ 'settingsaddressdialog.company' | transloco }}" matInput [(ngModel)]="address.company" formControlName="formCompany" required>
    <mat-error *ngIf="addressForm.get('formCompany')?.invalid">{{ 'settingsaddressdialog.hintcompany' | transloco }}</mat-error>
  </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'settingsaddressdialog.street' | transloco }}" matInput [(ngModel)]="address.street" formControlName="formStreet" required>
      <mat-error *ngIf="addressForm.get('formStreet')?.invalid">{{ 'settingsaddressdialog.hintstreet' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataFieldShort">
      <input placeholder="{{ 'settingsaddressdialog.zip' | transloco }}" matInput [(ngModel)]="address.zip" formControlName="formZip" required>
      <mat-error *ngIf="addressForm.get('formZip')?.invalid">{{ 'settingsaddressdialog.hintzip' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataFieldLong">
      <input placeholder="{{ 'settingsaddressdialog.city' | transloco }}" matInput [(ngModel)]="address.city" formControlName="formCity" required>
      <mat-error *ngIf="addressForm.get('formCity')?.invalid">{{ 'settingsaddressdialog.hintcity' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'settingsaddressdialog.country' | transloco }}" matInput [(ngModel)]="address.country" formControlName="formCountry" required>
      <mat-error *ngIf="addressForm.get('formCountry')?.invalid">{{ 'settingsaddressdialog.hintcountry' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'settingsaddressdialog.email' | transloco }}" matInput [(ngModel)]="address.email" formControlName="formEmail" required>
      <mat-error *ngIf="addressForm.get('formEmail')?.invalid">{{ 'settingsaddressdialog.hintemail' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'settingsaddressdialog.phone' | transloco }}" matInput [(ngModel)]="address.phone" formControlName="formPhone" required>
      <mat-error *ngIf="addressForm.get('formPhone')?.invalid">{{ 'settingsaddressdialog.hintphone' | transloco }}</mat-error>
    </mat-form-field>

    <!--
    <mat-form-field class="dataFieldMax">
      <textarea rows="5" placeholder="{{ 'articledialog.description' | transloco }}" matInput [(ngModel)]="article.description" [formControl]="formDescription" required></textarea>
      <mat-error *ngIf="formDescription.invalid">{{ 'articledialog.hintdescription' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataField33">
      <input placeholder="{{ 'articledialog.measurements' | transloco }}" matInput [(ngModel)]="article.measurements" [formControl]="formMeasurements" required>
      <mat-error *ngIf="formMeasurements.invalid">{{ 'articledialog.hintmeasurements' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataField33">
      <input placeholder="{{ 'articledialog.weight' | transloco }}" matInput [(ngModel)]="article.weight" [formControl]="formWeight" required>
      <mat-error *ngIf="formWeight.invalid">{{ 'articledialog.hintweight' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="dataField33">
      <input placeholder="{{ 'articledialog.amount' | transloco }}" matInput [(ngModel)]="article.amount" [formControl]="formAmount" required>
      <mat-error *ngIf="formAmount.invalid">{{ 'articledialog.hintamount' | transloco }}</mat-error>
    </mat-form-field>
    -->
  </form>

</div>

<div mat-dialog-actions class="actions">
  <span class="actionHint" *ngIf="addressForm.invalid">{{ 'settingsaddressdialog.hintmandatory' | transloco }}</span>
  <!--<button mat-button (click)="removeAddress(addressForm.)">{{ 'settingsaddressdialog.buttonCancel' | transloco }}</button>-->

  <button mat-button (click)="doAction()" [disabled]="addressForm.invalid"
          mat-flat-button color="accent">{{ 'settingsaddressdialog.buttonOK' | transloco }}</button>
  <button mat-button (click)="closeDialog()">{{ 'settingsaddressdialog.buttonCancel' | transloco }}</button>
</div>
