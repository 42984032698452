import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { APIService, Article, AvailableArticle, GetOrderQuery } from '../API.service';
import { NewOrderDialogBoxComponent } from '../new-order-dialog-box/new-order-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SendOrderInput } from "../API.service";

export interface OrderArticles extends AvailableArticle {
  orderAmount?: number;
}

@Component({
  selector: 'app-customer-new-order',
  templateUrl: './customer-new-order.component.html',
  styleUrls: ['./customer-new-order.component.scss']
})
export class CustomerNewOrderComponent implements OnInit {

  constructor(private api: APIService, public dialog: MatDialog, private route: ActivatedRoute, private router: Router) { }

  orderID = '';
  OrderDetails?: GetOrderQuery;
  ArticleList?: OrderArticles[];
  orderArticles: any;
  AvailableArticles: MatTableDataSource<OrderArticles> = new MatTableDataSource<OrderArticles>([]);
  selectingArticles = true;
  loadingBar = false;

  OrderAddressMandatory: any = localStorage.getItem('orderAddressMandatory');
  pickupAddress: any = localStorage.getItem('pickupAddress');
  hideOrderAddress: any = localStorage.getItem('hideOrderAddress');
  specificDeliveryTime: any = localStorage.getItem('specificDeliveryTime');
  showArticleAttributes: any = localStorage.getItem('showArticleAttributes');

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  // Dynamically create the displayed columns array based on variables
  displayedColumnsArticleList: string[] = this.generateDisplayedColumns();

  // Function to generate the displayed columns array dynamically
  private generateDisplayedColumns(): string[] {
    const defaultColumns: string[] = ['images', 'name', 'amount', 'orderAmount'];
    const dynamicColumns: string[] = [];

    // Add columns before 'weight'
    defaultColumns.forEach((column) => {
      if (column === 'amount') {
        // Add 'measurements and weight' column before 'amount' if not excluded
        if (this.showArticleAttributes == 'true') {
          dynamicColumns.push('measurements');
          dynamicColumns.push('weight');
        }
      }
      dynamicColumns.push(column);

    });

    return dynamicColumns;
  }

  ngOnInit() {
    const idParam = this.route.snapshot.paramMap.get('id');
    if (idParam !== null) {
      this.orderID = idParam;
    } else {
      this.orderID = '';
    }
    //console.log(this.orderID);
    this.loadingBar = true;

    //console.log('getting order...');
    this.api.GetOrder(this.orderID).
      then(order => {
        this.OrderDetails = order;
        console.log(this.OrderDetails);
      })
      .catch((error) => {
        console.log('could not get order');
      });

    //console.log('getting available articles...');
    this.api.GetAvailableArticlesForOrder(this.orderID).
      then(articles => {
        this.ArticleList = articles;
        this.ArticleList.forEach((element: OrderArticles, index: number) => {
          element.orderAmount = 0;
        });
        this.AvailableArticles = new MatTableDataSource(this.ArticleList as OrderArticles[]);

        this.AvailableArticles.paginator = this.paginator;
        //console.log("articles:")
        //console.table(this.AvailableArticles);
        this.loadingBar = false;
      })
      .catch((error) => {
        console.log('could not get articles');
      });
  }

  getArticleNameById(articleId: string | undefined): string {
    if (!articleId) {
      return 'Unknown'; // Handle cases where articleId is undefined
    }
    const article = this.AvailableArticles.data.find(item => item.id === articleId);
    return article?.name || 'Unknown'; // Always return a string
  }


  openDialog(action: string, obj?: any) {
    if (action === 'bearbeiten') {
      obj = {
        id: this.OrderDetails?.id,
        event: this.OrderDetails?.eventName,
        from: this.OrderDetails?.deliveryDate,
        to: this.OrderDetails?.pickupDate,
        deliveryWindow: this.OrderDetails?.deliveryWindow,
        pickupWindow: this.OrderDetails?.pickupWindow,
        eventStartDate: this.OrderDetails?.eventStartDate,
        eventEndDate: this.OrderDetails?.eventEndDate,
        costCenter: this.OrderDetails?.costCenter,
        additionalEmailAddresses: this.OrderDetails?.additionalEmailAddresses,
        address: {
          contactName: this.OrderDetails?.deliveryAddress?.contactName,
          contactNote: this.OrderDetails?.deliveryAddress?.contactNote,
          street: this.OrderDetails?.deliveryAddress?.street,
          zip: this.OrderDetails?.deliveryAddress?.zip,
          city: this.OrderDetails?.deliveryAddress?.city,
          company: this.OrderDetails?.deliveryAddress?.company,
          email: this.OrderDetails?.deliveryAddress?.email,
          phone: this.OrderDetails?.deliveryAddress?.phone,
          country: this.OrderDetails?.deliveryAddress?.country,
        },
        orderAddress: {
          contactName: this.OrderDetails?.orderAddress?.contactName,
          contactNote: this.OrderDetails?.orderAddress?.contactNote,
          street: this.OrderDetails?.orderAddress?.street,
          zip: this.OrderDetails?.orderAddress?.zip,
          city: this.OrderDetails?.orderAddress?.city,
          company: this.OrderDetails?.orderAddress?.company,
          email: this.OrderDetails?.orderAddress?.email,
          phone: this.OrderDetails?.orderAddress?.phone,
          country: this.OrderDetails?.orderAddress?.country,
        },
        pickupAddress: {
          contactName: this.OrderDetails?.pickupAddress?.contactName,
          contactNote: this.OrderDetails?.pickupAddress?.contactNote,
          street: this.OrderDetails?.pickupAddress?.street,
          zip: this.OrderDetails?.pickupAddress?.zip,
          city: this.OrderDetails?.pickupAddress?.city,
          company: this.OrderDetails?.pickupAddress?.company,
          email: this.OrderDetails?.pickupAddress?.email,
          phone: this.OrderDetails?.pickupAddress?.phone,
          country: this.OrderDetails?.pickupAddress?.country,
        },
        articles: [],
        state: this.OrderDetails?.state,
      };
    }
    const dialogRef = this.dialog.open(NewOrderDialogBoxComponent, {
      data: { ...obj, action }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const data = result.data;
      delete data.action;
      //console.log('Event:' + result.event)

      if (result.event === 'bearbeiten') {
        this.editOrderDetails(data);
      } else if (result.event === 'löschen') {
        // todo: DeleteOrder
        // this.deleteRowData(data);
        //console.log('deleted');
      }
    });
  }

  editOrderDetails(data: any) {
    if (!this.OrderDetails) {
      return;
    }

    this.OrderDetails.eventName = data.event;
    this.OrderDetails.deliveryDate = data.from;
    this.OrderDetails.pickupDate = data.to;
    this.OrderDetails.deliveryWindow = data.deliveryWindow;
    this.OrderDetails.pickupWindow = data.pickupWindow;
    this.OrderDetails.costCenter = data.costCenter;
    this.OrderDetails.additionalEmailAddresses = data.additionalEmailAddresses;

    this.OrderDetails.deliveryAddress = {
      __typename: 'ExtendedAddress',
      email: data.address.email,
      phone: data.address.phone,
      contactName: data.address.contactName,
      contactNote: data.address.contactNote,
      company: data.address.company,
      street: data.address.street,
      zip: data.address.zip,
      city: data.address.city,
      country: data.address.country,
    };

    // Check if orderAddress has all values undefined or null
    if (!data.orderAddress ||
      !data.orderAddress.email &&
      !data.orderAddress.phone &&
      !data.orderAddress.contactName &&
      !data.orderAddress.contactNote &&
      !data.orderAddress.company &&
      !data.orderAddress.street &&
      !data.orderAddress.zip &&
      !data.orderAddress.city &&
      !data.orderAddress.country) {
      // If all orderAddress fields are undefined or null, delete the pickupAddress property
      delete this.OrderDetails.orderAddress;
    } else {
      // Otherwise, assign the orderAddress
      this.OrderDetails.orderAddress = {
        __typename: 'ExtendedAddress',
        email: data.orderAddress?.email,
        phone: data.orderAddress?.phone,
        contactName: data.orderAddress?.contactName,
        contactNote: data.orderAddress?.contactNote,
        company: data.orderAddress?.company,
        street: data.orderAddress?.street,
        zip: data.orderAddress?.zip,
        city: data.orderAddress?.city,
        country: data.orderAddress?.country,
      };
    }

    // Check if pickupAddress has all values undefined or null
    if (!data.pickupAddress ||
      !data.pickupAddress.email &&
      !data.pickupAddress.phone &&
      !data.pickupAddress.contactName &&
      !data.pickupAddress.contactNote &&
      !data.pickupAddress.company &&
      !data.pickupAddress.street &&
      !data.pickupAddress.zip &&
      !data.pickupAddress.city &&
      !data.pickupAddress.country) {
      // If all pickupAddress fields are undefined or null, delete the pickupAddress property
      delete this.OrderDetails.pickupAddress;
    } else {
      // Otherwise, assign the pickupAddress
      this.OrderDetails.pickupAddress = {
        __typename: 'ExtendedAddress',
        email: data.pickupAddress?.email,
        phone: data.pickupAddress?.phone,
        contactName: data.pickupAddress?.contactName,
        contactNote: data.pickupAddress?.contactNote,
        company: data.pickupAddress?.company,
        street: data.pickupAddress?.street,
        zip: data.pickupAddress?.zip,
        city: data.pickupAddress?.city,
        country: data.pickupAddress?.country,
      };
    }
  }

  updateOrder(articledata: any, orderdata: any) {
    //console.log('Bestellung gespeichert');

    // Prepare articles
    this.orderArticles = articledata.filteredData.map((o: OrderArticles) => ({
      id: o.id,
      amount: o.orderAmount,
    }));
    orderdata.articles = this.orderArticles.filter((article: Article) => article.amount !== 0);

    //console.log(orderdata);
    this.selectingArticles = false;

    if (orderdata.articles.length > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Adds smooth scrolling animation
      });
    }

    // Base payload for SendOrder
    /*
    const updateOrderPayload: SendOrderInput = {
      eventName: orderdata.eventName,
      deliveryAddress: {
        company: orderdata.deliveryAddress?.company,
        contactName: orderdata.deliveryAddress?.contactName,
        contactNote: orderdata.deliveryAddress?.contactNote,
        email: orderdata.deliveryAddress?.email,
        phone: orderdata.deliveryAddress?.phone,
        street: orderdata.deliveryAddress?.street,
        zip: orderdata.deliveryAddress?.zip,
        city: orderdata.deliveryAddress?.city,
      },
      pickupAddress: orderdata.pickupAddress ? {
        company: orderdata.pickupAddress?.company,
        contactName: orderdata.pickupAddress?.contactName,
        contactNote: orderdata.pickupAddress?.contactNote,
        email: orderdata.pickupAddress?.email,
        phone: orderdata.pickupAddress?.phone,
        street: orderdata.pickupAddress?.street,
        zip: orderdata.pickupAddress?.zip,
        city: orderdata.pickupAddress?.city,
      } : undefined,
      deliveryWindow: orderdata.deliveryWindow,
      pickupWindow: orderdata.pickupWindow,
      articles: orderdata.articles,
      costCenter: orderdata.costCenter,
      additionalEmailAddresses: orderdata.additionalEmailAddresses,

      // Ensure orderAddress is always included
      orderAddress: this.OrderAddressMandatory === 'false'
        ? {
          company: orderdata.deliveryAddress?.company,
          contactName: orderdata.deliveryAddress?.contactName,
          contactNote: orderdata.deliveryAddress?.contactNote,
          email: orderdata.deliveryAddress?.email,
          phone: orderdata.deliveryAddress?.phone,
          street: orderdata.deliveryAddress?.street,
          zip: orderdata.deliveryAddress?.zip,
          city: orderdata.deliveryAddress?.city,
        }
        : {
          company: orderdata.orderAddress?.company,
          contactName: orderdata.orderAddress?.contactName,
          contactNote: orderdata.orderAddress?.contactNote,
          email: orderdata.orderAddress?.email,
          phone: orderdata.orderAddress?.phone,
          street: orderdata.orderAddress?.street,
          zip: orderdata.orderAddress?.zip,
          city: orderdata.orderAddress?.city,
        },
    };
    */

    //console.log("Sending order payload:", sendOrderPayload);

    // Call the API with the final payload
    /*
    this.api.SendOrder(orderdata.id, sendOrderPayload)
      .then((res) => {
        //console.log(res);
        this.router.navigate(['customer-orders']);
      });
     */
  }


  saveOrder(articledata: any, orderdata: any) {
    //console.log('Bestellung gespeichert');

    // Prepare articles
    this.orderArticles = articledata.filteredData.map((o: OrderArticles) => ({
      id: o.id,
      amount: o.orderAmount,
    }));
    orderdata.articles = this.orderArticles.filter((article: Article) => article.amount !== 0);

    //console.log(orderdata);

    // Base payload for SendOrder
    const sendOrderPayload: SendOrderInput = {
      eventName: orderdata.eventName,
      deliveryAddress: {
        company: orderdata.deliveryAddress?.company,
        contactName: orderdata.deliveryAddress?.contactName,
        contactNote: orderdata.deliveryAddress?.contactNote,
        email: orderdata.deliveryAddress?.email,
        phone: orderdata.deliveryAddress?.phone,
        street: orderdata.deliveryAddress?.street,
        zip: orderdata.deliveryAddress?.zip,
        city: orderdata.deliveryAddress?.city,
      },
      pickupAddress: orderdata.pickupAddress ? {
        company: orderdata.pickupAddress?.company,
        contactName: orderdata.pickupAddress?.contactName,
        contactNote: orderdata.pickupAddress?.contactNote,
        email: orderdata.pickupAddress?.email,
        phone: orderdata.pickupAddress?.phone,
        street: orderdata.pickupAddress?.street,
        zip: orderdata.pickupAddress?.zip,
        city: orderdata.pickupAddress?.city,
      } : undefined,
      deliveryWindow: orderdata.deliveryWindow,
      pickupWindow: orderdata.pickupWindow,
      articles: orderdata.articles,
      costCenter: orderdata.costCenter,
      additionalEmailAddresses: orderdata.additionalEmailAddresses,

      // Ensure orderAddress is always included
      orderAddress: this.OrderAddressMandatory === 'false'
        ? {
          company: orderdata.deliveryAddress?.company,
          contactName: orderdata.deliveryAddress?.contactName,
          contactNote: orderdata.deliveryAddress?.contactNote,
          email: orderdata.deliveryAddress?.email,
          phone: orderdata.deliveryAddress?.phone,
          street: orderdata.deliveryAddress?.street,
          zip: orderdata.deliveryAddress?.zip,
          city: orderdata.deliveryAddress?.city,
        }
        : {
          company: orderdata.orderAddress?.company,
          contactName: orderdata.orderAddress?.contactName,
          contactNote: orderdata.orderAddress?.contactNote,
          email: orderdata.orderAddress?.email,
          phone: orderdata.orderAddress?.phone,
          street: orderdata.orderAddress?.street,
          zip: orderdata.orderAddress?.zip,
          city: orderdata.orderAddress?.city,
        },
    };

    //console.log("Sending order payload:", sendOrderPayload);

    // Call the API with the final payload
    this.api.SendOrder(orderdata.id, sendOrderPayload)
      .then((res) => {
        //console.log(res);
        this.router.navigate(['customer-orders']);
      });
  }



  applyFilter(event: Event): void {
    if (this.AvailableArticles) {
      this.AvailableArticles.filter = (event.target as HTMLInputElement)?.value?.trim().toLowerCase();
    }
  }

  isRecent(createdAt: string | Date | null): boolean {
    if (!createdAt) {
      return false;
    }

    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const entryDate = typeof createdAt === 'string' ? new Date(createdAt) : createdAt;

    if (isNaN(entryDate.getTime())) {
      console.error('Invalid date format:', createdAt);
      return false;
    }

    return entryDate >= thirtyDaysAgo;
  }
}
