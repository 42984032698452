import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService, GetOrderQuery } from '../API.service';
import Auth from '@aws-amplify/auth';


@Component({
  selector: 'app-order-deliverynote',
  templateUrl: './order-deliverynote.component.html',
  styleUrls: ['./order-deliverynote.component.scss']
})
export class OrderDeliverynoteComponent implements OnInit {

  orderID = '';
  loadingBar = false;
  OrderDetail: GetOrderQuery | undefined;

  admin: boolean = false;
  customer: boolean = false;

  shipDate: any;

  constructor(private route: ActivatedRoute, private router: Router, private api: APIService) { }

  async ngOnInit() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      //console.log(user);
      if (user && user.signInUserSession.idToken.payload['cognito:groups']
        && user.signInUserSession.idToken.payload['cognito:groups'].indexOf('admin') >= 0) {
        this.admin = true;
        //console.log('Admin');
      }
      if (user && user.signInUserSession.idToken.payload['custom:customer']) {
        this.customer = true;
        //console.log('Customer');
      }
    } catch (e) {
      console.log(e);
    }

    this.orderID = this.route.snapshot.paramMap.get('id') ?? '';

    this.shipDate = new Date(); // Current Date

    this.loadingBar = true;
    //console.log('getting order...');
    this.api.GetOrder(this.orderID, this.admin ? this.route.snapshot.queryParamMap.get('customerId') ?? undefined : undefined)
      .then(order => {
        this.OrderDetail = order;
        //console.log(this.OrderDetail);
        this.loadingBar = false;
      })
      .catch((error) => {
        //console.log('could not get order');
      });
  }

  async shipOrder(customer: string, id: string, shippingID: string, shippingNote: string, shippingDate: string) {
    //console.log(id, shippingID, shippingNote, shippingDate);
    const res = await this.api.ShipOrder(customer, id, {
      shippingDate: shippingDate,
      shippingId: shippingID,
      note: shippingNote,
    });
    //console.log(`Shipped ${res}`);
    this.ngOnInit();
  }

  print() {
    window.print();
  }
}
