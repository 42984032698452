import { Component, OnInit } from '@angular/core';
import { APIService } from '../API.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-orders-report-detail',
  templateUrl: './admin-orders-report-detail.component.html',
  styleUrls: ['./admin-orders-report-detail.component.scss']
})
export class AdminOrdersReportDetailComponent implements OnInit {

  public year: string = '';
  public month: string = '';
  public customerId: string = '';

  reportDetails: any;

  constructor(private api: APIService, private route: ActivatedRoute) { }
  loadingBar = false;

  ngOnInit() {
    this.loadingBar = true;
    this.year = this.route.snapshot.paramMap.get('year')!;
    this.month = this.route.snapshot.paramMap.get('month')!;
    this.customerId = this.route.snapshot.paramMap.get('customerId')!;
    this.api.GetOrdersByCustomerAndMonth(this.customerId, this.year, this.month)
      .then(orders => {
        //console.log(orders);
        this.reportDetails = orders;
        this.loadingBar = false;
      })
      .catch((error) => {
        //console.log(error);
        console.log('could not get orders');
      });
  }

  print() {
    window.print();
  }
}
