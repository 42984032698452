import { Component, Inject, Optional, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { APIService, Customer, Order, UserAddress } from '../API.service';
import { Router } from '@angular/router';
import Auth from "@aws-amplify/auth";



@Component({
  selector: 'app-user-dialog-box',
  templateUrl: './new-order-dialog-box.component.html',
  styleUrls: ['./new-order-dialog-box.component.scss'],
})
export class NewOrderDialogBoxComponent implements OnInit {

  action: string;
  local_data: any;

  deliveryTimeHH: string;
  deliveryTimeMM: string;
  deliveryTimeAMPM: string;
  deliveryTimeCombined: string;

  pickupTimeHH: string;
  pickupTimeMM: string;
  pickupTimeAMPM: string;
  pickupTimeCombined: string;

  minDate: any;

  OrderAddressMandatory: any = localStorage.getItem('orderAddressMandatory');
  pickupAddress: any = localStorage.getItem('pickupAddress');
  hideOrderAddress: any = localStorage.getItem('hideOrderAddress');
  specificDeliveryTime: any = localStorage.getItem('specificDeliveryTime');

  CustomerInformation?: Customer;
  costCenters: any;
  selectedCostCenter: string;
  additionalCenter: string = '';

  additionalMail: string = ''; // Holds the input value
  additionalMails: string[] = []; // Array of split emails

  userAddresses: any;

  loadingBar = false;

  constructor(
    public dialogRef: MatDialogRef<NewOrderDialogBoxComponent>,
    private adapter: DateAdapter<any>,
    private api: APIService,
    private router: Router,

    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Order) {
    //console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
    //console.log('OrderAddressMandatory: ' + this.OrderAddressMandatory);

    this.deliveryTimeCombined = (data.deliveryWindow && data.deliveryWindow?.length > 0) ? data.deliveryWindow : '';
    this.deliveryTimeHH = this.deliveryTimeCombined.substring(0, 2);
    this.deliveryTimeMM = this.deliveryTimeCombined.substring(3, 5);
    this.deliveryTimeAMPM = this.deliveryTimeCombined.substring(6, 8);

    this.pickupTimeCombined = (data.pickupWindow && data.pickupWindow.length > 0) ? data.pickupWindow : '';
    this.pickupTimeHH = this.pickupTimeCombined.substring(0, 2);
    this.pickupTimeMM = this.pickupTimeCombined.substring(3, 5);
    this.pickupTimeAMPM = this.pickupTimeCombined.substring(6, 8);

    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);

    this.selectedCostCenter = this.local_data.costCenter;

    // Adjust the datepicker calendar to start from the minimum date
    this.adapter.setLocale('en'); // Change 'en' to your desired locale

  }

  async ngOnInit() {
    this.api.GetMyCustomer()
      .then(customer => {
        this.CustomerInformation = customer as Customer;

        // Check if costCenter exists, is an array, and has at least one element
        if (Array.isArray(this.CustomerInformation.costCenter) && this.CustomerInformation.costCenter.length > 0) {
          this.costCenters = this.CustomerInformation.costCenter;
          // Add "other" to the array
          this.costCenters.push('other');
        } else {
          // If costCenter is null, undefined, or empty, set costCenters to undefined
          this.costCenters = undefined;
        }

        //console.log(this.costCenters);
        this.initializeCostCenterFields();
      })
      .catch((error) => {
        console.log('could not get customer information');
      });

    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        //console.log('Benutzer: ' + user.username);
        this.userAddresses = await this.api.GetUserAddresses();
        //console.log('Adressen: ' + JSON.stringify(this.userAddresses[0]));
        this.loadingBar = false;
      }
    } catch (e) {
      if (e === 'not authenticated') {
        this.router.navigate(['/login']);
      }
      //console.log(e);
      this.loadingBar = false;
    }

    // Check if additionalEmailAddresses is defined in local_data
    if (this.local_data && this.local_data.additionalEmailAddresses && this.local_data.additionalEmailAddresses.length > 0) {
      // Join the array of email addresses into a comma-separated string and assign to additionalMail
      this.additionalMail = this.local_data.additionalEmailAddresses.join(', ');
    }
  }

  onPresetSelect(selectedAddress: any): void {
    // Ensure `local_data.address` exists to avoid undefined errors
    if (!this.local_data.address) {
      this.local_data.address = {};
    }

    if (selectedAddress) {
      // Copy only specific fields from `selectedAddress` to `local_data.address`
      this.local_data.address.company = selectedAddress.company;
      this.local_data.address.street = selectedAddress.street;
      this.local_data.address.zip = selectedAddress.zip;
      this.local_data.address.city = selectedAddress.city;
      this.local_data.address.country = selectedAddress.country;
      this.local_data.address.email = selectedAddress.email;
      this.local_data.address.phone = selectedAddress.phone;
    }
  }

  onPresetSelect2(selectedAddress: any): void {
    // Ensure `local_data.address` exists to avoid undefined errors
    if (!this.local_data.orderAddress) {
      this.local_data.orderAddress = {};
    }

    if (selectedAddress) {
      // Copy only specific fields from `selectedAddress` to `local_data.address`
      this.local_data.orderAddress.company = selectedAddress.company;
      this.local_data.orderAddress.street = selectedAddress.street;
      this.local_data.orderAddress.zip = selectedAddress.zip;
      this.local_data.orderAddress.city = selectedAddress.city;
      this.local_data.orderAddress.country = selectedAddress.country;
      this.local_data.orderAddress.email = selectedAddress.email;
      this.local_data.orderAddress.phone = selectedAddress.phone;
    }
  }

  onPresetSelect3(selectedAddress: any): void {
    // Ensure `local_data.address` exists to avoid undefined errors
    if (!this.local_data.pickupAddress) {
      this.local_data.pickupAddress = {};
    }

    if (selectedAddress) {
      // Copy only specific fields from `selectedAddress` to `local_data.address`
      this.local_data.pickupAddress.company = selectedAddress.company;
      this.local_data.pickupAddress.street = selectedAddress.street;
      this.local_data.pickupAddress.zip = selectedAddress.zip;
      this.local_data.pickupAddress.city = selectedAddress.city;
      this.local_data.pickupAddress.country = selectedAddress.country;
      this.local_data.pickupAddress.email = selectedAddress.email;
      this.local_data.pickupAddress.phone = selectedAddress.phone;
    }
  }

  // Method to initialize selectedCostCenter and additionalCenter based on local_data.costCenter
  initializeCostCenterFields() {
    //console.log(this.local_data.costCenter);
    if (this.local_data.costCenter) {
      // Split the local_data.costCenter value by comma and map parts with explicit string type
      const parts = this.local_data.costCenter.split(',').map((part: string) => part.trim());

      // Check if the first part matches an option in costCenters array
      if (this.costCenters.includes(parts[0])) {
        this.selectedCostCenter = parts[0]; // Set dropdown selection
        this.additionalCenter = parts.slice(1).join(', '); // Join any remaining parts as additionalCenter
      } else {
        // If no match, consider everything as additionalCenter
        this.additionalCenter = this.local_data.costCenter;
        this.selectedCostCenter = 'other'; // Clear the dropdown selection
      }
    }
  }

  onDeliveryTimeChange() {
    this.deliveryTimeCombined = '' + this.deliveryTimeHH + ':' + this.deliveryTimeMM + ' ' + this.deliveryTimeAMPM;
  }
  onPickupTimeChange() {
    this.pickupTimeCombined = '' + this.pickupTimeHH + ':' + this.pickupTimeMM + ' ' + this.pickupTimeAMPM;
  }

  onAdditionalMailChange(value: string): void {
    // Split the value by commas, trim whitespace, and filter out empty strings
    this.additionalMails = value
      .split(',')
      .map(email => email.trim())
      .filter(email => email !== '');
  }

  doAction() {

    this.local_data.additionalEmailAddresses = this.additionalMails;
    if (this.selectedCostCenter !== 'other') {
      this.additionalCenter = ''; // Clear additional input if "Other" is not selected
      this.local_data.costCenter = this.selectedCostCenter;
    } else {
      this.local_data.costCenter = [this.selectedCostCenter, this.additionalCenter].filter(Boolean).join(', ');
    }

    //console.log(this.selectedCostCenter);
    //console.log(this.local_data.additionalEmailAddresses);

    this.local_data.to = new DatePipe('en').transform(this.local_data.to, 'yyyy-MM-dd');
    this.local_data.from = new DatePipe('en').transform(this.local_data.from, 'yyyy-MM-dd');
    this.local_data.blockStart = new DatePipe('en').transform(this.local_data.blockStart, 'yyyy-MM-dd');
    this.local_data.blockEnd = new DatePipe('en').transform(this.local_data.blockEnd, 'yyyy-MM-dd');
    this.local_data.deliveryWindow = this.deliveryTimeCombined;
    this.local_data.pickupWindow = this.pickupTimeCombined;
    this.local_data.eventStartDate = new DatePipe('en').transform(this.local_data.eventStartDate, 'yyyy-MM-dd');
    this.local_data.eventEndDate = new DatePipe('en').transform(this.local_data.eventEndDate, 'yyyy-MM-dd');
    if (this.OrderAddressMandatory == 'false') {
      this.local_data.orderAddress.company = this.local_data.address.company;
      this.local_data.orderAddress.street = this.local_data.address.street;
      this.local_data.orderAddress.zip = this.local_data.address.zip;
      this.local_data.orderAddress.city = this.local_data.address.city;
      this.local_data.orderAddress.email = this.local_data.address.email;
      this.local_data.orderAddress.phone = this.local_data.address.phone;
      this.local_data.orderAddress.contactName = this.local_data.address.contactName;
      this.local_data.orderAddress.contactNote = this.local_data.address.contactNote;
    }

    if (this.pickupAddress == 'false') {
      // Remove pickupAddress from local_data if pickupAddress in localStorage is "false"
      delete this.local_data.pickupAddress;
    }
    if (this.hideOrderAddress == 'true') {
      // use customer data if order address is hidden
      this.local_data.orderAddress.company = this.CustomerInformation!.name;
      this.local_data.orderAddress.street = this.CustomerInformation!.address!.street;
      this.local_data.orderAddress.zip = this.CustomerInformation!.address!.zip;
      this.local_data.orderAddress.city = this.CustomerInformation!.address!.city;
      this.local_data.orderAddress.email = this.CustomerInformation!.email;
    }

    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
