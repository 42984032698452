import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularMaterialModule } from './angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { DragDropDirective } from './drag-drop.directive';
import { DatePipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { LoginComponent } from './login/login.component';
import { AdminCustomerComponent } from './admin-customer/admin-customer.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { AdminCustomerDetailComponent } from './admin-customer-detail/admin-customer-detail.component';

import { APIService } from './API.service';
import { CustomerArticlesComponent } from './customer-articles/customer-articles.component';
import { CustomerNewOrderComponent } from './customer-order/customer-new-order.component';
import { CustomerOrdersComponent } from './customer-orders/customer-orders.component';
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { UserSettingsAddressDialogComponent } from "./user-settings-address-dialog/user-settings-address-dialog.component";
import { UserSettingsAddressDelDialogComponent} from "./user-settings-address-del-dialog/user-settings-address-del-dialog.component";

import { CustomerDialogBoxComponent } from './customer-dialog-box/customer-dialog-box.component';
import { UserDialogBoxComponent } from './user-dialog-box/user-dialog-box.component';
import { ArticleDialogBoxComponent } from './article-dialog-box/article-dialog-box.component';
import { ArticleRemoveDialogBoxComponent } from './article-remove-dialog-box/article-remove-dialog-box.component';
import { NewOrderDialogBoxComponent } from './new-order-dialog-box/new-order-dialog-box.component';
import { HttpClientModule } from '@angular/common/http';
import { OrderDeliverynoteComponent } from './order-deliverynote/order-deliverynote.component';
import { AdminOrdersListComponent } from './admin-orders-list/admin-orders-list.component';
import { AdminOrdersReportComponent } from './admin-orders-report/admin-orders-report.component';
import { AdminOrdersReportDetailComponent } from './admin-orders-report-detail/admin-orders-report-detail.component';
import { OrderRemoveDialogBoxComponent } from './order-remove-dialog-box/order-remove-dialog-box.component';
import { TranslocoRootModule } from './transloco-root.module';
import { LanguageSelectorModule } from "./language-selector/language-selector.module";
import { Router } from '@angular/router';

export class CustomErrorHandler implements ErrorHandler {
  handleError(error: Error) {
    // Continue to log caught errors to the console
    console.error(error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminCustomerComponent,
    AdminUserComponent,
    AdminCustomerDetailComponent,
    CustomerArticlesComponent,
    CustomerNewOrderComponent,
    CustomerOrdersComponent,
    CustomerDialogBoxComponent,
    UserSettingsComponent,
    UserSettingsAddressDialogComponent,
    UserSettingsAddressDelDialogComponent,
    UserDialogBoxComponent,
    ArticleDialogBoxComponent,
    ArticleRemoveDialogBoxComponent,
    OrderRemoveDialogBoxComponent,
    NewOrderDialogBoxComponent,
    DragDropDirective,
    OrderDeliverynoteComponent,
    AdminOrdersListComponent,
    AdminOrdersReportComponent,
    AdminOrdersReportDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    LanguageSelectorModule,
    MatCheckboxModule
  ],
  providers: [
    APIService,
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, }) },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
