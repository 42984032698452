import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  newPasswordRequired = false;
  lostPasswordState = '';
  bgImage = 'url("../../assets/img/bg/1.jpg")';
  loginForm!: FormGroup;
  lostPasswordForm!: FormGroup;
  newPasswordForm!: FormGroup;
  user: any;
  loginError = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.changeBG();
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: [''],
    });
    this.lostPasswordForm = this.formBuilder.group({
      username: [''],
      code: [''],
      newpassword: ['', [Validators.compose([Validators.minLength(8), Validators.required])]],
    });
    this.newPasswordForm = this.formBuilder.group({
      newpassword: ['', [Validators.compose([Validators.minLength(8), Validators.required])]],
    });
  }

  changeBG() {
    const myImages = ['../../assets/img/bg/1.jpg', '../../assets/img/bg/2.jpg', '../../assets/img/bg/3.jpg', '../../assets/img/bg/4.jpg'];
    const newImgNumber = Math.floor(Math.random() * myImages.length);
    this.bgImage = 'url(' + myImages[newImgNumber] + ')';
  }

  async login() {
    return this.loginWithData(this.loginForm.controls['username'].value, this.loginForm.controls['password'].value);
  }


  async loginWithData(username: string, password: string) {
    //console.log('Login');
    try {
      this.user = await Auth.signIn(username, password);
      //console.log(this.user);
      if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.newPasswordRequired = true;
        this.loginForm.controls['username'].disable();
      } else {
        if (this.user && this.user.signInUserSession.idToken.payload['cognito:groups']
          && this.user.signInUserSession.idToken.payload['cognito:groups'].indexOf('admin') >= 0) {
          this.sendMessage('admin');
          this.router.navigate(['/admin-customer']);
        }
        if (this.user && this.user.signInUserSession.idToken.payload['custom:customer']) {
          this.sendMessage('customer');
          this.router.navigate(['/customer-orders']);
        }
      }
    } catch (e) {
      this.loginError = true;
      //console.log(e);
    }
  }

  async newPasswordSet() {
    //console.log('Resetting password');
    const res = await Auth.completeNewPassword(this.user, this.newPasswordForm.controls['newpassword'].value, {});
    //console.log('Resetting password done', res);
    if (res && res.signInUserSession.idToken.payload['cognito:groups']
      && res.signInUserSession.idToken.payload['cognito:groups'].indexOf('admin') >= 0) {
      this.sendMessage('admin');
      this.router.navigate(['/admin-customer']);
    }
    if (res && res.signInUserSession.idToken.payload['custom:customer']) {
      this.sendMessage('customer');
      this.router.navigate(['/customer-orders']);
    }
  }

  async lostPassword() {
    switch (this.lostPasswordState) {
      case 'username':
        await Auth.forgotPassword(this.lostPasswordForm.controls['username'].value);
        this.lostPasswordState = 'code';
        break;
      case 'code':
        await Auth.forgotPasswordSubmit(this.lostPasswordForm.controls['username'].value,
          this.lostPasswordForm.controls['code'].value, this.lostPasswordForm.controls['newpassword'].value);
          this.lostPasswordState = '';
        await this.loginWithData(this.lostPasswordForm.controls['username'].value, this.lostPasswordForm.controls['newpassword'].value);
        break;
      default:
        break;
    }
  }

  async finishLostPassword() {
  }

  sendMessage(text: string): void {
    // send message to subscribers via observable subject
    this.appService.sendMessage(text);
  }

}
