<div id="main" role="main">
  <h1>{{ 'adminordersreportdetail.titel' | transloco }}</h1>
  <button (click)="print()" mat-fab color="accent" attr.aria-label="{{ 'adminordersreportdetail.print' | transloco }}" class="no-print"><mat-icon>print</mat-icon></button>

  <h3 *ngIf="reportDetails?.length">{{ 'adminordersreportdetail.timespan' | transloco }} {{month}}-{{year}} | {{reportDetails[0]?.orderAddress?.company}}</h3>
  <h3 *ngIf="!reportDetails?.length && !loadingBar">{{ 'adminordersreportdetail.hint' | transloco }}</h3>

  <div>
    <table *ngIf="reportDetails?.length > 0" class="report-table" style="border-collapse: collapse;">
      <thead>
      <tr>
        <th>{{ 'adminordersreportdetail.event' | transloco }}</th>
        <th>{{ 'adminordersreportdetail.fromto' | transloco }}</th>
        <th>{{ 'adminordersreportdetail.article' | transloco }}</th>
        <th>{{ 'adminordersreportdetail.costCenter' | transloco }}</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let events of reportDetails" style="border-top: 1px solid black;">
          <td>
            {{ events.orderAddress?.company }}<br>
            {{ events.orderAddress?.contactName }}
          </td>
          <td><b> {{ events.deliveryDate | date:'dd.MM.yyyy'}}</b><br><b> {{events.pickupDate | date:'dd.MM.yyyy'}}</b></td>
          <td>
            <table>
              <tr *ngFor="let article of events?.articles">
                <td>{{article?.name}}</td><td style="text-align: right;">{{article?.amount}}</td>
              </tr>
            </table>
          </td>
          <td>
            {{ events.orderAddress?.costCenter }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!--
  <div class="table-container">

    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche">
    </mat-form-field>

    <div class="mat-elevation-z8">
      <mat-progress-bar *ngIf="loadingBar" mode="query"></mat-progress-bar>
      <table mat-table multiTemplateDataRows [dataSource]="ArticleList" class="article-table">

        <ng-container matColumnDef="images">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let articles" class="imageContainer">
            <div class="imageBox">
              <ng-container *ngFor="let file of articles?.assets; let i= index">
                <img class="uploadedImage" [src]="file.url"/>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Bezeichnung </th>
          <td mat-cell *matCellDef="let articles"> {{articles.name}} </td>
        </ng-container>

        <ng-container matColumnDef="measurements">
          <th mat-header-cell *matHeaderCellDef> Maße in mm (LxBxH) </th>
          <td mat-cell *matCellDef="let articles"> {{articles.measurements}} </td>
        </ng-container>

        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef> Gewicht in kg </th>
          <td mat-cell *matCellDef="let articles"> {{articles.weight}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Anzahl Artikel </th>
          <td mat-cell *matCellDef="let articles"> {{articles.amount}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let article" [attr.colspan]="displayedColumnsArticleList.length">
            <div class="article-detail"
                 [@detailExpand]="article == expandedElement ? 'expanded' : 'collapsed'">

              <div class="article-images">
                <ng-container *ngFor="let file of article.assets;">
                  <img class="article-image" [src]="file.url" alt="Artikelbild"/>
                </ng-container>
              </div>

              <h4>Beschreibung</h4>
              <p>{{article.description}}</p>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsArticleList"></tr>
        <tr mat-row *matRowDef="let article; columns: displayedColumnsArticleList;"
            class="article-row"
            [class.expanded-row]="expandedElement === article"
            (click)="expandedElement = expandedElement === article ? null : article">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

      </table>

      <mat-paginator [pageSizeOptions]="[5, 25, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </div>

  -->
</div>
